import * as React from "react"

function Svg(props) {
  return (
    <svg
      version="1.2"
      width="100%"
      height="100%"
      baseProfile="tiny"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2490.6 839.8"
      xmlSpace="preserve"
    >
      <path
        fill="#007DC1"
        d="M704.3,418c0-129.5,0.2-259.1-0.3-388.5c0-18,11.9-29.2,29.9-29.2c33.3-0.2,66.8-0.3,100.1,0
	c18.9,0.2,29.9,11.7,29.7,29.4c-0.5,114.6-0.3,229.4-0.3,344v6.3c0.2,12.7,5.8,22,17.5,27c11.3,4.8,21.9,2.3,31.1-7.5
	c33.6-36,67.1-72.1,100.5-108.2c20.5-22.2,41.3-44.1,61.6-66.4c7.7-8.4,16.6-12.4,28.1-12.2c41.9,0.3,84,0.3,125.9,0
	c12.4,0,22.2,3.8,27.4,15.3c5.3,11.9,2.8,22.5-6.1,32.2c-62.9,69.6-125.6,139.3-188.4,208.9c-5,5.6-9.9,11.4-13.8,17.8
	c-7,11.3-7,22.4-0.5,33.9c7,12.4,16.9,22.4,26.7,32.5c76.1,78.2,152.4,156.4,228.7,234.4c9.2,9.5,11.9,19.9,7.3,32.1
	c-4.4,11.4-13.1,16.6-27.4,16.6c-46.6,0-93.3-0.2-139.9,0c-10.5,0-18.6-3.3-26.1-11.3c-67.7-71.1-135.9-141.8-203.9-212.6
	c-9.7-10.2-20.5-12.8-31.7-8.1c-11.3,4.5-17.2,14.1-17.2,28v174.3c0,18.8-9.5,28.5-28.1,28.6H732.7c-20.5,0-28.5-8.1-28.5-28.3
	L704.3,418z M1339.7,287.7c0-85.7,0.3-171.5-0.3-257.2c-0.2-19.4,11.1-30.5,30.8-30.5c32.4,0.2,64.6,0,96.9,0
	c18.9,0,29.7,10.6,29.7,29.4c0.2,50,0.2,100.1,0.3,150.1c0,21.6,11.1,32.8,32.5,32.8h134.5c14.2,0,23.5,7.7,26.3,21.6
	c1.4,6.7,0.9,13.4,1.1,20.3v82.9c0,21.7-9.7,31.4-31.4,31.4c-43,0-86-0.2-129-0.2c-22,0-33.3,11.1-33.5,32.8
	c-0.2,44.1-0.9,88-0.3,132.1c0.9,67.2,52.4,129.1,119.3,144.9c19.7,4.7,39.7,5.5,59.7,2.7c16.3-2.3,28.1,6.4,30,22.8
	c4.1,34.9,7.8,69.9,11.4,104.8c1.4,13.3-8.3,25.5-22,27.7c-36.9,6.3-73.8,4.1-110.1-4.1C1456,803,1360.8,699.4,1342.2,568.1
	c-1.9-13-2.5-26-2.5-38.9L1339.7,287.7z M2490.4,697.2c0-11.9-9.1-20.2-21-20.8c-8.1-0.3-16.1-0.6-24.2-1.3
	c-41.4-3.1-62.7-22.5-70.2-63.3c-5.8-31.1-5.6-62.7-5.8-94.3c-0.3-90.7-0.2-181.4-0.2-272.1c0-2.7,0-5.2-0.2-7.8
	c-1.1-14.7-11.7-25.2-26.6-25.2c-34.9-0.2-69.9-0.2-104.8,0c-15.8,0-27,11.3-27.7,26.9c-0.2,3.8,0,7.7,0,12.2
	c-3-1.6-5.3-2.7-7.5-3.8c-63-33.1-129.8-43.8-200-32.1c-178.7,30-295.2,209-250.8,384.6c56.4,222.8,323.5,311.5,502.2,166.5
	c4.2-3.4,5.9-3.3,9.2,1.3c18.4,26.3,42.7,45.3,72.9,56.8c33.3,12.7,68,14.4,103.2,11.9c12.5-0.9,24.9-2.7,36.3-8.4
	c8.1-4.1,14.7-10.2,14.9-19.5C2490.8,771.6,2490.6,734.4,2490.4,697.2L2490.4,697.2z M2055.6,681.1c-86.9-0.3-156-71-155.7-159.6
	c0.3-83.8,71.3-153.4,156.5-153.2c87.4,0.2,156.4,70.4,156.2,159.2C2212.3,611.2,2140.8,681.2,2055.6,681.1z M314,211.1
	C148,211.4,3.8,339.6,0.1,517.7c-3.8,184.2,142.1,316,305,320.1c174.2,4.4,318.5-133.1,321.5-306.8C629.5,354.2,491,213.1,314,211.1
	L314,211.1z M312.9,681.1c-85.7,0-156-70.4-156.2-156.4c-0.2-86.1,70-156.4,156.5-156.7c86.3-0.2,156.2,69.9,156.2,156.5
	S399.5,681.1,312.9,681.1L312.9,681.1z"
      />
    </svg>
  )
}

export default Svg
